import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { API_ROOT_URL } from '~constants/api-constants'
import api from '~apis/api'

const TwitterPage = () => {
  const location = useLocation()

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${API_ROOT_URL}/auth/twitter/callback${location.search}`)
      .then((res) => res.json())
      .then(async (res) => {
        if (res.error) {
          throw res
        }

        const searchParams = new URLSearchParams(location.search)

        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        localStorage.setItem('user_jwt', res.jwt)

        if (!res.user.accessToken) {
          await api.updateUser(
            searchParams.get('access_token'),
            searchParams.get('access_secret'),
            res.user.id
          )
        }

        const result = await api.getUserInfo()
        localStorage.setItem('user_name', result.name)
        localStorage.setItem('user_profile_img', result.profile_image_url)

        console.log(
          'You have been successfully logged in. You will be redirected in a few seconds...'
        )

        navigate('/')
      })
      .catch((err) => {
        let errType = ''
        if (err.statusCode === 400) {
          errType = 'twitterNoEmail'
        }

        navigate(`/${err?.message?.message ? `?err=${errType}` : ''}`)
      })
  }, [location.search])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}

export default TwitterPage
